import {
  loadContentPage,
  loadContentPageByIdentifier,
} from "@storefront/core/data-resolver/cms";
import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  current: null, // shown product
  default: null,
  currentId: null,
});

const actions = {
  async load({ commit }, { id = 0 }) {
    const page = await loadContentPage(id).catch((e) => {
      Logger.error("loadContentPage", "cms block store actions single", e)();
      throw e;
    });
    //if (page.content.search("mailto:") == -1) {
    page.content = page.content.replace(/<a href=/g, "<b-link to=");
    page.content = page.content.replace(/<a/g, "<b-link");
    page.content = page.content.replace(/<\/a>/g, "</b-link>");
    page.content = page.content.replace(/ href="\//g, ' to="');
    page.content = page.content.replace(
      /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
      '<a$1href="$2$3</a>'
    );
    // }
    commit("setCurrentPage", page);
    return page;
  },
  async loadDefault({ commit }, { id = "" }) {
    const page = await loadContentPageByIdentifier(id).catch((e) => {
      Logger.error(
        "loadContentPageByIdentifier",
        "cms block store actions loadDefault",
        e
      )();
      throw e;
    });
    commit("setDefaultPage", page);
    return page;
  },
  setPage({ commit }, { page }) {
    //if (page.content.search("mailto:") == -1) {
    page.content = page.content.replace(/<a href=/g, "<b-link to=");
    page.content = page.content.replace(/<a/g, "<b-link");
    page.content = page.content.replace(/<\/a>/g, "</b-link>");
    page.content = page.content.replace(/ href="\//g, ' to="');
    page.content = page.content.replace(
      /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
      '<a$1href="$2$3</a>'
    );
    // }
    commit("setCurrentPage", page);
  },
};
const mutations = {
  // always and only change vuex state through mutations.
  setCurrentPage(state, data) {
    state.current = data;
  },
  setDefaultPage(state, data) {
    state.default = data;
  },
  setCurrentId(state, data) {
    state.currentId = data;
  },
};

const getters = {
  getCurrentPage: (state) => state.current,
  getDefaultPage: (state) => state.default,
  getCurrentId: (state) => state.currentId,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
