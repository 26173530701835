const Home = () =>
  import(/* webpackChunkName: "sf-home" */ "@/esf_antarctica_topdesign/core/views/home/Home.vue");

const Blog = () =>
    import(
      /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/blog/Blog.vue"
    );

const Blogs = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/blog/Blogs.vue"
  );

const CreateAccount = () =>
  import(
    /* webpackChunkName: "sf-create-account" */ "@/base/core/views/authentication/sign-up/SignUp.vue"
  );

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "sf-forgot-password" */ "@/base/core/views/authentication/forgot-password/ForgotPassword.vue"
  );

const ResetPassword = () =>
  import(
    /* webpackChunkName: "sf-forgot-password" */ "@/base/core/views/authentication/reset-password/ResetPassword.vue"
  );

const Category = () =>
  import(
    /* webpackChunkName: "sf-category-page" */ "@/esf_antarctica_topdesign/core/views/category-product/Category.vue"
  );

const Faq = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "@/base/core/views/faq/Faq.vue");

const PageNotFound = () =>
  import(
    /* webpackChunkName: "sf-page-not-found" */ "@/esf_antarctica_topdesign/core/views/pageNotFound/pageNotFound.vue"
  );

const Login = () =>
  import(
    /* webpackChunkName: "sf-login" */ "@/base/core/views/authentication/login/Login.vue"
  );

const Account = () =>
  import(
    /* webpackChunkName: "sf-create-account" */ "@/base/core/views/account/Account.vue"
  );

const CmsPage = () =>
  import(/* webpackChunkName: "sf-cms-page" */ "@/base/core/views/static-page/StaticPage.vue");

const Product = () =>
  import(
    /* webpackChunkName: "sf-product" */ "@/esf_antarctica_topdesign/core/views/product-detail/Product.vue"
  );
  
const Cart = () =>
  import(/* webpackChunkName: "sf-cart" */ "@/base/core/views/cart/Cart.vue");
  
const Checkout = () =>
  import(
    /* webpackChunkName: "sf-checkout" */ "@/esf_antarctica_topdesign/core/views/checkout/Checkout.vue"
  );

const PaymentSuccess = () =>
  import(
    /* webpackChunkName: "sf-payment-success" */ "@/esf_antarctica_topdesign/core/views/payment-info/PaymentSuccess.vue"
  );

const PaymentCancel = () =>
  import(
    /* webpackChunkName: "sf-payment-cancel" */ "@/esf_antarctica_topdesign/core/views/payment-info/PaymentCancel.vue"
  );

const Brands = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/brands/Brands.vue"
  );

const Search = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/search/Search.vue"
  );

// const Sale = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "@/esf_antarctica_topdesign/core/views/Sale.vue");

const Contact = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/contact/Contact.vue"
  );

const CustomerService = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/customer-service/CustomerService.vue"
  );

const ComparePage = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "@/base/core/views/compare/ComparePage.vue"
  );


const routes = [
  { name: "home", path: "/", component: Home },
  { name: "login", path: "/login", component: Login },
  { name: "signUp", path: "/create-account", component: CreateAccount },
  {
    name: "forgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "reset-password",
    path: "/customer/account/createPassword",
    component: ResetPassword,
  },
  { name: "blogs", path: "/blog", component: Blogs },
  { name: "blog", path: "/blog/:code", component: Blog },

  { name: "category-page", path: "/category-page", component: Category },
  {
    name: "faq",
    path: "/faq",
    component: Faq,
  },
  {
    name: "contact",
    path: "/contact",
    component: Contact,
  },
  { name: "brands", path: "/merken", component: Brands },

  // product
  { name: "product-SimpleProduct", path: "/product", component: Product },
  {
    name: "product-ConfigurableProduct",
    path: "/productconfig",
    component: Product,
  },
  {
    name: "product-BundleProduct",
    path: "/productbundle",
    component: Product,
  },
  {
    name: "product-page",
    path: "/product",
    component: Product,
  },

  {
    name: "product-LookProduct",
    path: "/productlook",
    component: Product,
  },
  { name: "cart", path: "/checkout/cart", component: Cart },
  { name: "account", path: "/account", component: Account },
  {
    name: "customerService",
    path: "/customer-service",
    component: CustomerService,
  },
  { name: "checkout", path: "/checkout", component: Checkout },
  {
    name: "onpagesuccess",
    path: "/checkout/onepage/success",
    component: PaymentSuccess,
  },
  {
    name: "onpagecancel",
    path: "/checkout/onepage/cancel",
    component: PaymentCancel,
  },
  {
    name: "multisafepaysuccess",
    path: "/multisafepay/connect/success",
    component: PaymentSuccess,
  },
  {
    name: "multisafepaycancel",
    path: "/multisafepay/connect/cancel",
    component: PaymentCancel,
  },
  {
    name: "compare",
    path: "/compare",
    component: ComparePage,
  },
  { name: "cms-page", path: "/cms-page", component: CmsPage },


  { name: "cms-page", path: "/cms-page", component: CmsPage },
  { name: "search", path: "/search", component: Search },
  { name: "error", path: "/error", component: PageNotFound },

  // 404
  { name: "pageNotFound", path: "/page-not-found", component: PageNotFound },
];

export default routes;
