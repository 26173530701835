import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 *
 * @param {integer} id
 * @returns slider object
 */
export async function loadSliderById(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ getTailoredSlider(id: " +
    id +
    ") { title fullwidth location slides { title identifier description link buttonText media { url type previewType } } } }\n";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadSliderById", "data-resolver sliders", e)();
    throw e;
  });
  return retval.data.data.getTailoredSlider[0];
}

/**
 *
 * @param {integer} id
 * @returns slider object
 */
export async function loadSliderByCategoryID(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{ getTailoredSlider(pageType: "categories" pageId:' +
    id +
    ") { title fullwidth location slides { title description link buttonText media { url type previewType } } } }\n";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadSliderById", "data-resolver sliders", e)();
    throw e;
  });
  return retval.data.data.getTailoredSlider[0];
}

/**
 *
 * @param {array} ids
 * @returns array of sliders
 */
export async function loadSliderByIdentifiers(ids) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ getTailoredSlider(identifiers: " +
    JSON.stringify(ids) +
    ") { title identifier fullwidth location slides { title description link buttonText media { url type previewType smallest smaller medium larger largest } } } }\n";

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadSliderByIdentifiers", "data-resolver sliders", e)();
    throw e;
  });
  return retval.data.data.getTailoredSlider;
}
