import Vue from "vue";
import VueI18n from "vue-i18n";
//import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

Vue.use(VueI18n);
let currentTemplate = "";
const lang = config.languages.default;
if (config.template.name != "") {
  currentTemplate = config.template.name + "/";
} else {
  currentTemplate = config.template.name;
}

/**
 *
 */
export const i18n = new VueI18n({
  locale: getI18nDefault(), // set locale
  fallbackLocale: config.languages.default,
  messages: {
    [lang]: require(`../../src/${currentTemplate}i18n/${lang}.json`),
  },
});

/**
 * get the default language which is configured in the local.json
 * @returns default language
 */
function getI18nDefault() {
  return config.languages.default;
}

const loadedLanguages = [config.languages.default]; // our default language that is preloaded

/**
 * set current language
 *
 * @param {string} lang
 * @returns language code
 */
function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

/**
 * get current language
 *
 * @returns language code
 */
export function getCurrentLanguage() {
  return i18n.locale;
}

/**
 * get the default language which is configured in the local.json
 * @returns default language
 */
export function getDefaultLanguage() {
  return config.languages.default;
}

/**
 * Load Language async
 * @param {string} lang
 * @returns language code
 */
export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `../../src/${currentTemplate}/${lang}.json`
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    i18n.locale = lang;
    return i18n.locale;
  });
}
