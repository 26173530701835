import axios from "axios";
import config from "@config";
import graphql from "@graphql";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 * load category by id
 *
 * @param {integer} id
 * @returns category object or false
 */
export async function loadCategoryById(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let end_query = "";
  let query = '{ categoryList(filters: {ids: {eq: "' + id + '"}';
  query = query + end_query + "}) ";

  query = query + graphql.queryFields.category + "}";

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadCategoryById", "data-resolver categories", e)();
    throw e;
  });
  if (retval.data.data.categoryList != null) {
    return retval.data.data.categoryList[0];
  } else {
    return false;
  }
}

/**
 * load category by id with the graphql categorydeep fieldset
 *
 * @param {integer} id
 * @returns category object or false
 */
export async function loadCategoryDeepById(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    '{ categoryList(filters: {ids: {eq: "' +
    id +
    '"}}) {' +
    graphql.queryFields.categorydeep +
    "}}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadCategoryDeepById", "data-resolver categories", e)();
    throw e;
  });
  if (retval.data.data.categoryList != null) {
    return retval.data.data.categoryList[0];
  } else {
    return false;
  }
}

/**
 * load category for menu by id
 *
 * @param {integer} id
 * @returns category object or false
 */
export async function loadCategoryMenu(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    '{ categoryList(filters: {ids: {eq: "' +
    id +
    '"}}) ' +
    graphql.queryFields.menu +
    "}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadCategoryDeepById", "data-resolver categories", e)();
    throw e;
  });
  if (retval.data.data.categoryList != null) {
    return retval.data.data.categoryList[0];
  } else {
    return false;
  }
}

/**
 * load Bestsellers by id with the graphql
 *
 * @param {integer} id
 * @returns array of bestsellers or false
 */
export async function getBestSellers(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ bestsellers( categoryId: " +
    id +
    ' sources: [ { priority: 1, type: "category", sourceField: "up_sell" } ] limit: 10 ) { tab_count tabs { title option_id item_count items { sku name __typename image { url } small_image { url } thumbnail { url } url_key  price_range { minimum_price { discount { amount_off percent_off } regular_price { value } final_price { value } } maximum_price { discount { amount_off percent_off } regular_price { value } final_price { value } } } special_from_date special_to_date special_price new_from_date new_to_date } } }}';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadBestSellers", "data-resolver categories", e)();
    throw e;
  });
  if (retval.data.data.bestsellers != null) {
    return retval.data.data.bestsellers;
  } else {
    return false;
  }
}
