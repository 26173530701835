import Vue from "vue";
import Vuex from "vuex";
import BreadCrumbs from "@storefront/core/modules/breadcrumbs/store";
import Category from "@storefront/core/modules/catalog/store/category";
import Messages from "@storefront/core/modules/messages/store";
import Product from "@storefront/core/modules/catalog/store/product";
import Search from "@storefront/core/modules/catalog/store/search";
import cmsBlocks from "@storefront/core/modules/cms/store/block";
import sliders from "@storefront/core/modules/cms/store/sliders";
import stores from "@storefront/core/modules/cms/store/stores";
import cmsPage from "@storefront/core/modules/cms/store/page";
import User from "@storefront/core/modules/user/store";
import FAQ from "@storefront/core/modules/faq/store";
import Cart from "@storefront/core/modules/cart/store";
import Forms from "@storefront/core/modules/forms/store";
import Blog from "@storefront/core/modules/blog/store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {},
  strict: debug,
});

store.registerModule("breadcrumbs", BreadCrumbs);

store.registerModule("category", Category);
store.registerModule("messages", Messages);
store.registerModule("cmsBlock", cmsBlocks);
store.registerModule("sliders", sliders);
store.registerModule("stores", stores);
store.registerModule("user", User);
store.registerModule("cart", Cart);
store.registerModule("product", Product);
store.registerModule("search", Search);
store.registerModule("forms", Forms);
store.registerModule("faq", FAQ);
store.registerModule("blog", Blog);

store.registerModule("cmsPage", cmsPage);

Vue.prototype.$store = store;

export default store;
