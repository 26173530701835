import { Logger } from "@storefront/core/lib/logger";
import {
  login,
  newsletterSignup,
  getCurrent,
  createAddress,
  updateAddress,
  deleteAddress,
  changePassword,
  createAccount,
  updateAccount,
  getCountries,
  addProductToWishlist,
  removeProductFromWishlist,
  getMyOrders,
} from "@storefront/core/data-resolver/user";
import {
  getCustomerCartToken,
  mergeCart,
} from "@storefront/core/data-resolver/cart";
import store from "@/store";
import { i18n } from "@storefront/core/i18n";

const state = () => ({
  isLoggedIn: false,
  userToken: null,
  userEmail: null,
  current: {},
  countries: [],
  accountTabIndex: 0,
  editAddressId: 0,
  editUser: false,
  editEmail: false,
  editPassword: false,
  editAddresses: false,
  Fake: null,
  wishlist: { id: null, items: [] },
  myOrders: [],
});

const actions = {
  /**
   * Load active user
   *
   */
  async loadUser({ getters, commit, dispatch }) {
    Logger.debug("loadUser", "store", "start")();

    if (getters["getIsLoggedIn"] == false) {
      if (getters["getUserToken"] == null) {
        const userToken = localStorage.getItem("userServerToken");
        if (userToken !== null) {
          commit("setUserToken", userToken);
          commit("setIsLoggedIn", true);
          const me = await getCurrent();
          if (me == false) {
            dispatch("logout");
          } else {
            commit("setCurrentUser", me);
            dispatch("loadOrders");
            let wl = me.wishlist;
            Logger.debug("me.wishlist", "loadUser", wl)();
            if (wl == null) {
              wl = { id: null, items: [] };
            }
            commit("setWishlist", wl);
            const newCartToken = await getCustomerCartToken();
            store.commit("cart/setServerToken", newCartToken);
            await store.dispatch("cart/loadCart");
          }
        }
      }
    } else {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        dispatch("loadOrders");
      }
    }
  },
  /**
   * Load user orders
   *
   */
  async loadOrders({ commit }) {
    const myOrders = await getMyOrders();
    Logger.debug("loadOrders", "myOrders", myOrders)();
    if (myOrders != false) {
      /*
      myOrders.items.forEach((element) => {
        element.price = element.grand_total.toFixed(2).replace(".", ",");
      });
      */
      commit("setMyOrders", myOrders.items);
    }
  },
  async login({ commit }, { username = "", password = "" }) {
    Logger.debug("login store", "username", username)();
    Logger.debug("login store", "password", password)();
    const retval = await login(username, password);

    if (retval != false) {
      commit("setUserToken", retval);
      commit("setIsLoggedIn", true);
      const me = await getCurrent();
      Logger.debug("login", "me", me)();
      if (me != false) {
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        commit("setCurrentUser", me);
        const newCartToken = await getCustomerCartToken();
        const oldCartToken = store.getters["cart/getCartServerToken"];
        if (oldCartToken != null) {
          await mergeCart(oldCartToken, newCartToken);
        }
        store.commit("cart/setServerToken", newCartToken);
        await store.dispatch("cart/loadCart");
      } else {
        commit("setUserToken", null);
        commit("setIsLoggedIn", false);
        commit("setCurrentUser", {});
      }
    } else {
      return false;
    }
    return true;
  },
  /**
   * Signup for newsletter
   *
   * @param {object} string email
   * @returns
   */
  async newsletterSignup({ commit, dispatch }, { email = "" }) {
    commit("setFake", null);
    const retval = await newsletterSignup(email);
    if (retval != false) {
      dispatch("loadUser");
      return true;
    }
    return false;
  },
  /**
   * Load countries
   *
   * @returns true
   */
  async loadCountries({ commit }) {
    const retval = await getCountries();
    commit("setCountries", retval);
    return true;
  },
  /**
   * Create user account
   *
   * @param {object} object account
   * @returns
   */
  async createAccount({ commit }, { account }) {
    commit("setFake", null);
    Logger.debug("UserStore", "account", account)();
    const retval = await createAccount(account);

    if (retval != false) {
      commit("setUserToken", retval);
      commit("setIsLoggedIn", true);
      const me = await getCurrent();
      Logger.debug("login", "me", me)();
      if (me != false) {
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        commit("setCurrentUser", me);
        const newCartToken = await getCustomerCartToken();
        const oldCartToken = store.getters["cart/getCartServerToken"];
        if (oldCartToken != null) {
          await mergeCart(oldCartToken, newCartToken);
        }
        store.commit("cart/setServerToken", newCartToken);
        await store.dispatch("cart/loadCart");
      } else {
        commit("setUserToken", null);
        commit("setIsLoggedIn", false);
        commit("setCurrentUser", {});
      }
    } else {
      return false;
    }
    return true;
  },
  /**
   * add product to wishlist
   *
   * @param {object} string sku, string parentSku
   * @returns true or false
   */
  async addProductToWishlist({ dispatch }, { sku, parentSku }) {
    const retval = await addProductToWishlist(sku, parentSku);
    if (retval == true) {
      dispatch("loadUser");
    }
    return retval;
  },
  /**
   * remove product from wishlist
   *
   * @param {object} integer id
   * @returns true or false
   */
  async removeProductFromWishlist({ dispatch }, { id }) {
    const retval = await removeProductFromWishlist(id);
    if (retval == true) {
      dispatch("loadUser");
    }
    return retval;
  },

  /**
   * update account
   *
   * @param {object} object account
   * @returns
   */
  async updateAccount({ commit }, { account }) {
    const retval = await updateAccount(account);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },
  /**
   * add address
   *
   * @param {object} object address
   * @returns true or false
   */
  async addAddress({ commit }, { address }) {
    const retval = await createAddress(address);
    Logger.debug("user", "store", retval)();
    if (retval == true) {
      const me = await getCurrent();
      Logger.debug("user me", "store", me)();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * updaet address
   *
   * @param {object} object address
   * @returns true or false
   */
  async updateAddress({ commit }, { address }) {
    const retval = await updateAddress(address.id, address);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * update user newsletter
   *
   * @param {object} boolean is_subscribed
   * @returns true or false
   */
  async updateUserNewsletter({ commit }, { is_subscribed }) {
    const account = { is_subscribed: is_subscribed };
    const retval = await updateAccount(account);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * delete address
   *
   * @param {object} integer id
   * @returns
   */
  async deleteAddress({ commit }, { id }) {
    const retval = await deleteAddress(id);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * change password
   *
   * @param {object} string currentPassword, string newPassword
   * @returns
   */
  async changePassword({ commit }, { currentPassword, newPassword }) {
    commit("setFake", null);
    const retval = await changePassword(currentPassword, newPassword);
    return retval;
  },

  /**
   * logout
   *
   */
  logout({ commit }) {
    commit("setUserToken", null);
    commit("setIsLoggedIn", false);
    commit("setCurrentUser", {});
    commit("setWishlist", { id: null, items: [] });
    store.dispatch("cart/unLoad");
  },
  /**
   * session expired
   *
   */
  sessionExpired({ dispatch }) {
    dispatch("logout");
    const msg = {
      type: "danger",
      title: "session_expired",
      text: i18n.t("session_expired_error"),
    };
    store.dispatch("messages/sendMessage", { message: msg });
  },
  /**
   * set product wishlist status
   *
   * @param {object} string sku, string parentSku
   */
  async setProductWishlistStatus({ getters, dispatch }, { sku, parentSku }) {
    Logger.debug("setProductWishlistStatus sku", "Store", sku)();
    Logger.debug("setProductWishlistStatus parentSku", "Store", parentSku)();
    const id = getters["getProductInWishlistId"](sku);
    if (id == null) {
      const retval = await addProductToWishlist(sku, parentSku);
      Logger.debug("setProductWishlistStatus retval", "Store", retval)();

      if (retval == true) {
        dispatch("loadUser");
      }
    } else {
      const retval = await removeProductFromWishlist(id);
      if (retval == true) {
        dispatch("loadUser");
      }
    }
  },
  /**
   * remove product wishlist
   *
   * @param {object} integer id
   */
  async removeProductWishlist({ dispatch }, { id }) {
    const retval = await removeProductFromWishlist(id);
    if (retval == true) {
      dispatch("loadUser");
    }
  },
};

const mutations = {
  /**
   * set user token
   *
   * @param {string} payload
   */
  setUserToken(state, payload) {
    state.userToken = payload;
    if (payload != null) {
      localStorage.setItem("userServerToken", payload);
    } else {
      localStorage.removeItem("userServerToken");
    }
  },
  /**
   * Set is logged in
   *
   * @param {boolean} payload
   * @private
   */
  setIsLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  /**
   * set Current user
   *
   * @param {object} payload
   * @private
   */
  setCurrentUser(state, payload) {
    state.current = payload;
  },
  /**
   * set countries
   *
   * @param {array} payload
   * @private
   */
  setCountries(state, payload) {
    state.countries = payload;
  },
  /**
   * set account tab index
   *
   * @param {integer} payload
   */
  setAccountTabIndex(state, payload) {
    state.accountTabIndex = payload;
  },
  /**
   * set Edit address id
   *
   * @param {integer} payload
   */
  setEditAddressId(state, payload) {
    state.editAddressId = payload;
  },
  setEditAddresses(state, payload) {
    state.editAddresses = payload;
  },
  /**
   * set wishlist
   *
   * @param {object} payload
   */
  setWishlist(state, payload) {
    if (payload == null) {
      payload = { id: null, items: [] };
    }
    state.wishlist = payload;
  },
  /**
   * a fake set when you don't need any other object in you dispatch
   *
   * @param {object} state
   * @param {any} payload
   * @private
   *
   */
  setFake(state, payload) {
    state.fake = payload;
  },
  /**
   * set orders
   *
   * @param {array} payload
   */
  setMyOrders(state, payload) {
    state.myOrders = payload;
  },
  setEditUser(state, payload) {
    state.editUser = payload;
  },
  setEditPassword(state, payload) {
    state.editPassword = payload;
  },
  setEditEmail(state, payload) {
    state.editEmail = payload;
  }
};

const getters = {
  getIsLoggedIn: (state) => state.isLoggedIn,
  getCurrentUser: (state) => state.current,
  getUserToken: (state) => state.userToken,
  getUserEmail: (state) => state.userEmail,
  getWishlist: (state) => state.wishlist.items,
  getMyOrders: (state) => state.myOrders,
  getWishlistId: (state) => state.wishlist.id,
  getEditUser: (state) => state.editUser,
  getEditPassword: (state) => state.editPassword,
  getEditAddresses: (state) => state.editAddresses,

  getEditEmail: (state) => state.editEmail,
  getWishlistQuantity: (state) => state.wishlist.items.length,
  getCountries: (state) => {
    const countries = [];
    state.countries.forEach(function(row) {
      const country = { value: row.id, text: row.full_name_locale };
      countries.push(country);
    });
    return countries;
  },
  getRegions: (state) => (id) => {
    const country = state.countries.find((o) => {
      if (o.id == id) {
        return true; // stop searching
      }
    });
    if (country != null) {
      if (country.available_regions != null) {
        const regions = [];
        country.available_regions.forEach((element) => {
          const option = {
            value: element.id,
            text: element.name,
          };
          regions.push(option);
        });
        return regions;
      } else {
        return null;
      }
    }
  },
  getDefaultShippingAddress: (state) => {
    if (typeof state.current.addresses != "undefined") {
      const index = state.current.addresses.findIndex(
        (item) => item.id == state.current.default_shipping
      );
      return state.current.addresses[index];
    } else {
      return null;
    }
  },
  getDefaultBillingAddress: (state) => {
    if (typeof state.current.addresses != "undefined") {
      const index = state.current.addresses.findIndex(
        (item) => item.id == state.current.default_billing
      );
      return state.current.addresses[index];
    } else {
      return null;
    }
  },
  getAddressByID: (state) => (id) => {
    //Logger.debug("test", "store", id)();
    if (typeof state.current.addresses != "undefined") {
      const index = state.current.addresses.findIndex((item) => item.id == id);
      return state.current.addresses[index];
    } else {
      return null;
    }
  },
  isProductInWishlist: (state) => (sku) => {
    //    Logger.debug("test sku", "store", sku)();
    if (state.wishlist.items.length > 0) {
      const obj = state.wishlist.items.find((o) => {
        //Logger.debug("test list", "store", o.product.sku)();
        if (o.product.sku == sku) {
          return true;
        }
      });
      //      Logger.debug("test obj", "store", obj)();
      if (obj == null) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
  getProductInWishlistId: (state) => (sku) => {
    //Logger.debug("test", "store", id)();
    if (state.wishlist.items.length > 0) {
      const obj = state.wishlist.items.find((o) => {
        if (o.product.sku == sku) {
          return true;
        }
      });
      if (obj == null) {
        return null;
      } else {
        return obj.id;
      }
    } else {
      return null;
    }
  },

  getAccountTabIndex: (state) => state.accountTabIndex,
  getEditAddressId: (state) => state.editAddressId,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
