import axios from "axios";
import config from "@config";
import graphql from "@graphql";

import { getCurrentLanguage } from "@storefront/core/i18n";
//import { Logger } from "@storefront/core/lib/logger";

/**
 *
 * @param {string} url
 * @returns url resolver object
 */
export async function urlResolver(url) {
  const lang = getCurrentLanguage();

  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  if (config.template.htmlUrl) url += ".html";
  const query =
    '{ urlResolver(url: "' + url + '") {id relative_url redirectCode type}}';
  try {
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    });
    return retval.data.data.urlResolver;
  } catch (e) {
    console.log(e);
  }
}

/**
 *
 * @param {string} url
 * @returns router object
 */
export async function urlRoute(url) {
  const lang = getCurrentLanguage();

  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    '{ route(url: "' +
    url +
    '") {__typename relative_url redirect_code type ... on ProductInterface { sku } ... on CmsPage { identifier url_key title content content_heading page_layout meta_title meta_description meta_keywords } ... on CategoryTree ' +
    graphql.queryFields.category +
    "}}";
  try {
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    });
    return retval.data.data.route;
  } catch (e) {
    console.log(e);
  }
}
