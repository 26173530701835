import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router";
//import { VueRouterEx } from "@storefront/core/lib/router";
import { beforeEachGuard } from "@storefront/core/router/beforeEach.js";
//import {Logger} from "@storefront/core/lib/logger";

Vue.use(VueRouter);

export default () => {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });

  router.beforeEach(async (to, from, next) => {
    return await beforeEachGuard(to, from, next, router);
  });

  return router;
};
