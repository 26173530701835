import config from "@config";
import { loadLanguageAsync } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
import {
  cleanPath,
  extractFilters,
} from "@storefront/core/router/helpers/cleanPath.js";
import { router } from "@/main";
//import { checkServerItems } from "@storefront/core/router/checkServerItems";
import { urlRoute } from "@storefront/core/data-resolver/url";
import store from "@/store";

export async function beforeEachGuard(to, from, next) {
  //check language
  let lang = "";
  const split = to.path.split("/");
  if (config.languages.available.includes(split[1])) {
    lang = await loadLanguageAsync(split[1]).catch((e) => {
      Logger.error("loadLanguageAsync1", "beforeEachGuard", e)();
      throw e;
    });
  } else {
    lang = await loadLanguageAsync(config.languages.default).catch((e) => {
      Logger.error("loadLanguageAsync2", "beforeEachGuard", e)();
      throw e;
    });
  }
  Logger.debug("lang", "beforeEachGuard", lang)();
  const path = cleanPath(to);
  //let resolve = null;

  if (to.path === from.path) return next();

  const resolve = await urlRoute(path).catch((e) => {
    Logger.error("urlResolver", "beforeEachGuard", e)();
    throw e;
  });

  if (resolve != null) {
    Logger.debug("route", "beforeEachGuard", "FOUND")();
    Logger.debug("route type", "beforeEachGuard", resolve.type)();
    //Load the information per type of route
    switch (resolve.type) {
      case "CATEGORY": {
        let load = true;
        const obj = store.getters["category/getCurrentCategory"];
        if (obj != null && obj.id == resolve.id) {
          load = false;
        }
        if (load) {
          const filter = extractFilters(to);
          store.dispatch("category/setCategory", {
            category: resolve,
            filter: filter,
          });
        }
        break;
      }
      case "PRODUCT": {
        let load = true;
        const obj = store.getters["product/getCurrentProduct"];
        if (obj != null) {
          if (obj.sku == resolve.sku) {
            load = false;
          }
        }
        if (load) {
          await store
            .dispatch("product/loadWithSku", {
              sku: resolve.sku,
            })
            .catch((e) => {
              Logger.error("product/loadWithSku", "beforeEachGuard", e)();
              throw e;
            });
        }
        break;
      }
      case "CMS_PAGE": {
        store.dispatch("cmsPage/setPage", {
          page: resolve,
        });

        break;
      }
    }
  } else {
    Logger.debug("route", "beforeEachGuard", "NOT FOUND")();
  }

  if (to.matched.length === 0) {
    const testRoute = router.options.routes.find((e) => {
      if (e.path === path) {
        return true;
      }
    });
    if (testRoute != null) {
      Logger.debug("testRoute", "beforeEachGuard", testRoute)();
      router.addRoutes({
        path: to.path,
        component: testRoute.component,
      });
      next(to.fullPath);
      return;
    }
    if (resolve != null) {
      let route = false;
      switch (resolve.type) {
        case "CATEGORY": {
          const testPage = router.options.routes.find(
            (e) => e.name === "category-page"
          );
          Logger.debug("testPage", "beforeEachGuard", testPage)();
          if (testPage != null) {
            route = {
              name: "category-" + lang + "-" + path.replace("/", "-"),
              path: to.path,
              component: testPage.component,
            };
          }
          break;
        }
        case "PRODUCT": {
          const testPage = router.options.routes.find(
            (e) => e.name === "product-page"
          );
          Logger.debug("testPage", "beforeEachGuard", testPage)();
          if (testPage != null) {
            route = {
              name: "product-" + lang + "-" + path.replace("/", "-"),
              path: to.path,
              component: testPage.component,
            };
          }
          break;
        }
        case "CMS_PAGE": {
          const testPage = router.options.routes.find(
            (e) => e.name === "cms-page"
          );
          Logger.debug("testPage", "beforeEachGuard", testPage)();
          if (testPage != null) {
            route = {
              name: "cms-" + lang + "-" + path.replace("/", "-"),
              path: to.path,
              component: testPage.component,
            };
          }
          break;
        }
      }
      if (route !== false) {
        router.addRoute(route);
        Logger.debug("route added", "beforeEachGuard", route.name)();
        Logger.debug("next", "beforeEachGuard", to.fullPath)();
        return next(to.fullPath);
      }
      Logger.debug("next", "beforeEachGuard", "route not found")();
      return next();
    }
  } else {
    if (store.getters["user/getIsLoggedIn"] == true) {
      if (to.name === "login") {
        Logger.debug(
          "logged in request to login",
          "beforeEachGuard",
          to.name
        )();
        return next({
          name: "account",
        });
      } else if (to.name === "create-account") {
        Logger.debug(
          "logged in request to create-account",
          "beforeEachGuard",
          to.name
        )();
        return next({
          name: "account",
        });
      } else if (to.name === "en-login") {
        Logger.debug(
          "logged in request to login",
          "beforeEachGuard",
          to.name
        )();
        return next({
          name: "en-account",
        });
      } else if (to.name === "en-create-account") {
        Logger.debug(
          "logged in request to create-account",
          "beforeEachGuard",
          to.name
        )();
        return next({
          name: "en-account",
        });
      }
    } else {
      if (to.name === "account") {
        Logger.debug(
          "not logged in request to account",
          "beforeEachGuard",
          to.name
        )();
        return next({
          name: "login",
        });
      } else if (to.name === "en-account") {
        Logger.debug(
          "not logged in request to account",
          "beforeEachGuard",
          to.name
        )();
        return next({
          name: "en-login",
        });
      }
    }
    Logger.debug("next", "beforeEachGuard", "match is found go to match")();
    // the route and component are known and data is available
    return next();
  }
  Logger.debug("next", "beforeEachGuard", "page-not-found")();
  return next("/page-not-found");
}
