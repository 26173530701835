import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 * Load FAQ from magento
 *
 * @returns return faq object
 */
export async function loadFAQ() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "query { faq { page_info { current_page page_size total_pages } total_count items { category_id title url_key url faq { total_count items { question short_answer long_answer } } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadFAQ", "data-resolver faq", e)();
    throw e;
  });
  return retval.data.data.faq;
}
