import { loadGeneralMsg } from "@storefront/core/data-resolver/general";
import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  msgItems: [],
  msgIndex: 0,
  lastMsg: 0,
  generalMsg: { text: "", background_color: "#fff", text_color: "#fff" },
});

const actions = {
  /**
   * Send message
   *
   * @param {object} object message
   */
  sendMessage({ commit }, { message }) {
    commit("setMessage", message);
  },
  /**
   * give last messages
   *
   * @returns array
   */
  giveLastMessages({ commit, state }) {
    const msgs = state.msgItems.map((obj) => ({ ...obj }));
    const send = [];
    for (let i = state.lastMsg; i <= state.msgItems.length; i++) {
      //Logger.debug("message msglist", "store", msgs[i])();
      if (msgs[i] != null) {
        send.push(msgs[i]);
      }
      //Logger.debug("message send", "store", send)();
      commit("setLastMsg", i);
    }

    return send;
  },
  /**
   * Load the general message from magento
   *
   */
  async loadGeneralMsg({ commit }) {
    const generalMsg = await loadGeneralMsg().catch((e) => {
      Logger.error("loadGeneralMessage", "load general message in store", e)();
      throw e;
    });
    Logger.debug("generalMsg", "load general message in store", generalMsg)();
    if (generalMsg != false) {
      commit("setGeneralMsg", generalMsg);
    }
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  /**
   * set message
   *
   * @param {object} data
   */
  setMessage(state, data) {
    state.msgItems.push(data);
    state.msgIndex++;
  },
  /**
   * set last message
   *
   * @param {integer} data
   */
  setLastMsg(state, data) {
    Logger.debug("message lastMsg", "store", data)();
    state.lastMsg = data;
  },
  /**
   * set general message
   *
   * @param {object} data
   * @private
   */
  setGeneralMsg(state, data) {
    state.generalMsg = data;
  },
};

const getters = {
  getMessages: (state) => {
    return state.msgItems;
  },
  getGeneralMsg: (state) => state.generalMsg,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
